var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"boolean-condition"},[_c('switch-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': _vm.model,
        'key': 'value',
        'prefix': 'face_control_boolean_condition_'
      },
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }