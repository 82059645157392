















import { InputSetups } from '@/mixins/input-setups'
import { FaceControlBooleanConditions } from '@/includes/types/FaceControl.types'

import { UseFields } from 'piramis-base-components/src/components/Pi'

import { Component, Mixins, VModel } from 'vue-property-decorator'
import { BooleanCondition } from '@/includes/logic/FaceControl/FaceControlConditions'

@Component({})
export default class BooleanConditionView extends Mixins<UseFields, InputSetups>(UseFields, InputSetups) {
  @VModel() model!:BooleanCondition
}
